import * as React from "react";
import SEO from "../components/SEO";
import { ROUTE_LINKS } from "../utils/routes";
import PageWrapper from "../components/PageWrapper";

const NotFoundPage = () => {
  return (
    <PageWrapper>
      <SEO pageUrl={ROUTE_LINKS.notFound} />
      <main className="max-w-6xl md:mx-auto">
        <h1 className="flex flex-col items-center my-32">
          <span className="text-9xl heading">404</span>
          <span className="text-3xl font-bold font-primary">
            Page Not Found
          </span>
        </h1>
      </main>
    </PageWrapper>
  );
};

export default NotFoundPage;
